import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { nightsCount: Number }

  static targets = ['guestsCount', 'totalBednights']

  updateGuestsCount (e) {
    this.guestsCountTarget.value = e.detail.guestsCount
    this.guestsCountTarget.dispatchEvent(new Event('input'))
  }

  updateTotalBednights (e) {
    const guestsCount = parseInt(e.target.value) || 0
    const totalBednights = guestsCount * this.nightsCountValue
    this.totalBednightsTarget.textContent = totalBednights
  }
}
