import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static values = {
    position: {
      type: String,
      default: 'input'
    },
    maxTags: {
      type: Number,
      default: Infinity
    }
  }

  static targets = ['input']

  get tagifyInstance () {
    return new Tagify(this.inputTarget, {
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(', '),
      tagTextProp: 'name',
      editTags: false,
      mode: this.element.dataset.mode || null,
      dropdown: {
        position: this.positionValue,
        enabled: 0,
        maxItems: 100,
        placeAbove: false,
        fuzzySearch: false
      },
      whitelist: JSON.parse(this.element.dataset.whitelist || '[]'),
      templates: {
        dropdownItemNoMatch: this.#dropdownItemNoMatchTemplate,
        dropdownFooter: this.#dropdownFooterTemplate,
        dropdownItem: this.#dropdownItem
      },
      maxTags: this.maxTagsValue
    })
  }

  connect () {
    this.tagify = this.tagifyInstance
    this.#setupInputListener()
    this.tagify.on('dropdown:select', this.#onSelectSuggestion.bind(this))
    this.tagify.setContentEditable(!this.tagify.hasMaxTags())
    this.tagify.on('change', () => {
      this.tagify.setContentEditable(!this.tagify.hasMaxTags())
      this.dispatch('tagifyChange', { detail: { tagify: this.tagify } })
    })
  }

  disconnect () {
    this.tagify.off('input')
  }

  reload () {
    this.disconnect()
    this.connect()
  }

  #setupInputListener () {
    let controller = new AbortController()

    this.tagify.on('input', (event) => {
      const path = this.inputTarget.dataset.tagsPath

      if (path) {
        const { value } = event.detail
        this.tagify.settings.whitelist.length = 0

        controller.abort()
        controller = new AbortController()
        this.tagify.loading(true)

        fetch(`${path}?query=${value}`, { signal: controller.signal })
          .then((response) => response.json())
          .then((whitelist) => {
            this.tagify.whitelist = whitelist
            this.tagify.loading(false).dropdown.show(value)
          })
      }
    })
  }

  #onSelectSuggestion (event) {
    if (event.detail.elm.classList.contains('tagify__dropdown__item__addNew')) {
      this.tagify.addTags(this.tagify.state.inputText)
      this.tagify.DOM.input.innerHTML = ''
    }
  }

  #dropdownItemNoMatchTemplate () {
    return `
      <div class='tagify-suggestions-noMatch' value="noMatch" tabindex="0" role="option">
        No results found
      </div>`
  }

  #dropdownItem (item) {
    return `
      <div
        ${this.getAttributes(item)}
        class='${this.settings.classNames.dropdownItem} ${this.isTagDuplicate(item.value) ? this.settings.classNames.dropdownItemSelected : ''} ${item.class || ''}'
        tabindex="0"
        role="option">
          <div class='tagify__dropdown__item__text'>
            ${item.mappedValue || item.value}
          </div>
      </div>`
  }

  #dropdownFooterTemplate () {
    return `
      <div class="select_footer select_footer-tagify">
        <div class="select_footer__new" tabindex="-1">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.8125 9H15.1875" stroke="#3F3C43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 2.8125V15.1875" stroke="#3F3C43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Create</span>
        </div>
        <div class="select_footer__help">
          <div class="select_footer__item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 11L5 13L3 11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 3V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 5L11 3L13 5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11 13V3" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            to navigate
          </div>
          <div class="select_footer__item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 8L3 11L6 14" stroke="#3F3C43" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 2V11H3" stroke="#3F3C43" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            to select
          </div>
          <div class="select_footer__item"><b>esc</b> to dismiss</div>
        </div>
      </div>
    `
  }
}
