import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'
import { get } from '@rails/request.js'
import debounce from 'lodash.debounce'

export default class Flyout extends Controller {
  static targets = ['template']
  static classes = ['show']
  static values = {
    url: String,
    position: { type: String, default: 'left' },
    isOpen: { type: Boolean, dafault: false }
  }

  initialize () {
    this.flyout = this.#createFlyoutElement()
    this.calculatePosition = debounce(this.#calculatePosition, 250)
  }

  connect () {
    window.addEventListener('resize', this.calculatePosition.bind(this))
  }

  disconnect () {
    window.removeEventListener('resize', this.calculatePosition.bind(this))
  }

  clickOutside (e) {
    this.hide()
  }

  async show () {
    if (this.isOpenValue === true) {
      this.hide()
      return
    }

    this.isOpenValue = true
    const content = this.hasTemplateTarget ? this.templateTarget.innerHTML : await this.#fetchContent()
    if (!content) return

    this.#renderFlyout(content)
    document.body.appendChild(this.flyout)
    this.#calculatePosition()
    useClickOutside(this, { element: this.flyout })
  }

  hide () {
    if (this.flyout) {
      setTimeout(() => {
        this.isOpenValue = false
      }, 100)
      this.flyout.remove()
    }
  }

  #createFlyoutElement () {
    const flyout = document.createElement('div')
    flyout.classList.add('flyout')
    if (this.hasShowClass) {
      flyout.classList.add(...this.showClasses)
    }
    flyout.id = 'flyout'
    return flyout
  }

  #renderFlyout (content) {
    const fragment = document.createRange().createContextualFragment(content)
    this.flyout.innerHTML = ''
    this.flyout.appendChild(fragment)
  }

  #calculatePosition () {
    const rect = this.element.getBoundingClientRect()
    const flyoutHeight = this.flyout.offsetHeight
    const spaceBelow = window.innerHeight - rect.bottom
    const spaceAbove = rect.top

    if (spaceBelow < flyoutHeight && spaceAbove > flyoutHeight) {
      this.flyout.style.top = `${rect.top + window.scrollY - flyoutHeight}px`
    } else {
      this.flyout.style.top = `${rect.bottom + window.scrollY}px`
    }

    if (this.positionValue === 'right') {
      this.flyout.style.right = `${window.innerWidth - rect.right + window.scrollX}px`
    } else {
      this.flyout.style.left = `${rect.left + window.scrollX}px`
    }
  }

  async #fetchContent () {
    if (this.hasUrlValue) {
      try {
        const response = await get(this.urlValue)
        const content = await response.text()
        return content
      } catch (error) {
        console.error('[stimulus-popover] Failed to fetch the popover content.', error)
      }
    }
  }
}
