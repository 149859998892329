import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { guestsCount: Number }

  setGuestsCount (event) {
    this.guestsCountValue = parseInt(event.target.value) || 1
    this.dispatch('guestsCountChanged', { detail: { guestsCount: this.guestsCountValue } })
  }
}
