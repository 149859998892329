import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select', 'container']

  static values = {
    url: String,
    param: String
  }

  connect () {
    if (this.selectTarget.id === '') {
      this.selectTarget.id = Math.random().toString(36)
    }
  }

  change (event) {
    const params = new URLSearchParams()
    if (event.target.type === 'radio') {
      params.append(this.paramValue, event.target.value)
    } else {
      params.append(this.paramValue, event.target.selectedOptions[0].value)
    }
    params.append('target', this.selectTarget.id)
    params.append('container', this.containerTarget.id)

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    })
  }
}
